<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          Creditop
        </h2>

        <!-- <b-img
          :src="require('@/assets/images/logo/creditop.jpeg')"
          fluid
          alt="Homecenter"
          width="150px"
        /> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >

          <div v-for="allie in allies" :key="allie.allie">

            <div v-if="allie.allie == allieOnboarding">

              <b-img
                :src="allie.logoImage"
                fluid
                alt="Homecenter"
              />

              <b-card-text class="mb-2 mt-1 text-center">
                {{ allie.copy }}
              </b-card-text>

            </div>
          </div>
          <validation-observer ref="onboardingRules">

            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="initializeMetamap"
            >

              <b-form-group
                label="Monto a solicitar"
                label-for="number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Monto a solicitar"
                  rules="required"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      $
                    </b-input-group-prepend>
                    <cleave
                      id="number"
                      v-model="form.amount"
                      class="form-control"
                      :raw="false"
                      :options="options.number"
                      placeholder="300,000"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Cuotas"
                label-for="fee_number"
              >
                <b-form-select
                  id="fee_number"
                  v-model="form.feeNumber"
                  :options="feeNumberOptions"
                />
              </b-form-group>

              <b-form-group
                label="Ocupación"
                label-for="occupation"
              >
                <b-form-select
                  id="occupation"
                  v-model="form.occupation"
                  :options="occupationOptions"
                />
              </b-form-group>

              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                block
                size="lg"
              >
                <span
                  class="align-middle"
                >
                  Solicitar
                </span>

                <feather-icon
                  icon="ArrowRightCircleIcon"
                  class="ml-50"
                  size="24"
                />
              </b-button>

            </b-form>

          </validation-observer>

          <div class="d-none d-lg-block">

            <div class="divider my-2">
              <div class="divider-text">
                o puedes
              </div>
            </div>

            <!-- scan button -->
            <b-button
              v-b-modal.qr-modal
              variant="outline-primary"
              type="submit"
              block
            >
              <span class="align-middle">Continuar en Celular</span>

              <feather-icon
                icon="SmartphoneIcon"
                class="ml-50"
              />
            </b-button>

            <b-card-text class="mb-2 mt-2 text-center">
              Para una mejor experiencia continua en el celular escaneando el <b>codigo QR</b>
            </b-card-text>

          </div>

          <b-card-text class="mb-2 mt-4 text-center text-muted">
            Derechos reservados Creditop
          </b-card-text>

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>

    <!-- modal QR scan for continue in cell phone Modal-->
    <b-modal
      id="qr-modal"
      title="Escanea el QR"
      ok-title="Entendido"
      ok-only
      cancel-variant="outline-secondary"
    >
      <b-card-text>
        Lee el código QR y continua tu solicitud desde el celular para una mejor experiencia
      </b-card-text>

      <div class="bg-light-primary rounded-top text-center">
        <b-img
          :src="require('@/assets/images/allies/qr-ctop-homecenter-dev.png')"
          alt="Meeting Pic"
          fluid
        />
      </div>

    </b-modal>

    <metamap-button
      ref="button"
      style="display: none"
      :clientid="clientid"
      :flowid="flowid"
    />

  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BImg, BButton, BCardText,
  BFormGroup,
  BFormInput,
  BForm,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { codeMask } from '@/views/forms/form-element/form-input-mask/code'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BButton,
    BCardText,
    VuexyLogo,
    BFormGroup,
    BFormInput,
    BForm,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    Cleave,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      clientid: process.env.VUE_APP_CLIENTID,
      //flowid: '643eee20c1083e001ced49d4', // DEV
      flowid: '643d36472389d9001b27ce64', // QA
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      form: {
        amount: null,
        feeNumber: null,
        occupation: null,
      },
      options: {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+63',
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },
      },
      feeNumberOptions: [
        {
          text: 'Selecciona las cuotas',
          value: null,
          disabled: true,
        },
        {
          text: '3 meses',
          value: 3,
        },
        {
          text: '6 meses',
          value: 6,
        },
        {
          text: '9 meses',
          value: 9,
        },
        {
          text: '12 meses',
          value: 12,
        },
      ],
      occupationOptions: [
        {
          text: 'Selecciona una ocupación',
          value: null,
          disabled: true,
        },
        {
          text: 'Empleado',
          value: 'Empleado',
        },
        {
          text: 'Independiente',
          value: 'Independiente',
        },
        {
          text: 'Microempresario',
          value: 'Microempresario',
        },
        {
          text: 'Pensionado',
          value: 'Pensionado',
        },
      ],
      allieOnboarding: '',
      allies: [
        {
          allie: 'homecenter',
          name: 'Homecenter',
          copy: 'Descubre multiples opciones de financiamiento y paga con la que prefieras',
          logoImage: require('@/assets/images/allies/logo-homecenter_1.jpg'),
        },
        {
          allie: 'estetica',
          name: 'Dra Luz Marina - Medicina Estética',
          copy: 'Estar en manos expertas, garantiza resultados visibles, seguros y duraderos, ven y mejora tu apariencia física y tu bienestaro',
          logoImage: require('@/assets/images/allies/logo-homecenter_1.jpg'),
        },
      ],
      codeMask,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    this.allieOnboarding = this.$route.query.allie
    // console.log(process.env.VUE_APP_ENDPOINT)
    // console.log(this.$route.query.allie)
  },
  mounted() {
    this.$refs.button.addEventListener('metamap:loaded', ({ detail }) => console.log('[METAMAP] Loaded', detail))
    this.$refs.button.addEventListener('metamap:userStartedSdk', ({ detail }) => {
      console.log('[METAMAP] User started verification', detail)
    })
    this.$refs.button.addEventListener('metamap:userFinishedSdk', ({ detail }) => {
      console.log('[METAMAP] User finished verification', detail)
      this.$router.push({ name: 'list-lenders' })
    })
    this.$refs.button.addEventListener('metamap:exitedSdk', ({ detail }) => console.log('[METAMAP] User exited verification', detail))
  },
  methods: {
    initializeMetamap() {
      event.preventDefault()
      this.$refs.onboardingRules.validate().then(success => {
        if (success) {

          localStorage.setItem('form', JSON.stringify(this.form))

          this.$refs.button.dispatchEvent(new Event('click'))
          /* this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se ha enviado la información',
              icon: 'CheckIcon',
              variant: 'success',
            },
          }) */
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

metamap-button {
  display: block;
  width: 100%;
}

</style>
